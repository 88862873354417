import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import CartContext, { products } from '../../context/CartContext';
import CallToAction from './call-to-action';
import * as styles from './essential.module.scss';
import { getOptionSentence } from './helpers';
import Program from './rec-program';
import video from '../../images/blog/Video_blog.mp4';
/**
 * BeMum acquisition funnel > Results page
 * All Essential Item (food supplements, coaching and app)
 *
 * @param {Object} props - Results state
 * @param {boolean} props.essentialOnly - No options have a score >= 20
 *
 * @returns {React.ReactElement}
 * @see resultats.js
 */

function Essential({ scoreObject, optionName, firstname }) {
  const { cart, setCart } = useContext(CartContext);
  const {
    star,
    img_fabrique,
    img_livraison,
    img_naturel,
    img_recommande,
    img_essentiel,
    img_pplus,
    img_splus,
    img_coaching,
    img_complet,
    arrow_scroll_down,
  } = useStaticQuery(graphql`
    query EssentialQuery {
      star: file(relativePath: { eq: "icons/star.svg" }) {
        publicURL
      }
      img_fabrique: file(relativePath: { eq: "resultats/fabrique.png" }) {
        childImageSharp {
          gatsbyImageData(width: 52, placeholder: NONE)
        }
      }
      img_livraison: file(relativePath: { eq: "resultats/livraison.png" }) {
        childImageSharp {
          gatsbyImageData(width: 52, placeholder: NONE)
        }
      }
      img_naturel: file(relativePath: { eq: "resultats/nat.png" }) {
        childImageSharp {
          gatsbyImageData(width: 52, placeholder: NONE)
        }
      }
      img_recommande: file(relativePath: { eq: "resultats/recom.png" }) {
        childImageSharp {
          gatsbyImageData(width: 52, placeholder: NONE)
        }
      }
      img_pplus: file(relativePath: { eq: "programme/programme2025/age.svg" }) {
        publicURL
      }
      img_splus: file(relativePath: { eq: "programme/programme2025/sopk.svg" }) {
        publicURL
      }
      img_complet: file(relativePath: { eq: "programme/programme2025/complet.svg" }) {
        publicURL
      }
      img_coaching: file(relativePath: { eq: "resultats/coaching.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 208, placeholder: NONE)
        }
      }
      img_essentiel: file(relativePath: { eq: "programme/programme2025/essentiel.svg" }) {
        publicURL
      }
      arrow_scroll_down: file(relativePath: { eq: "arrows/arrow-scroll-down.svg" }) {
        publicURL
      }
    }
  `);

  const [sentenceResult, setSentenceResult] = useState(null);
  const [withoutOptions, setwithoutOptions] = useState(null);
  const [score, setScore] = useState([]);
  useEffect(() => {
    if (!scoreObject) return;
    const sentenceResult = getOptionSentence(scoreObject);
    setSentenceResult(sentenceResult);
    const withoutOptions = optionName.name + optionName.plus;
    setwithoutOptions(withoutOptions);
    const score = scoreObject;
    setScore(score);

    if (scoreObject) {
      const { antiox, poids } = scoreObject;

      if (antiox >= 20 && poids >= 20) {
        setCart('add', 'program', 'complet');
      } else if (antiox >= 20) {
        setCart('add', 'program', 'age');
      } else if (poids >= 20) {
        setCart('add', 'program', 'sopk');
      } else {
        setCart('add', 'program', 'essentiel');
      }
    }
  }, [scoreObject, withoutOptions, score]);

  const [isFixed, setIsFixed] = useState(false);
  const ref = useRef(null);

  function listenToScroll() {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();

    const windowScrollHeight = document.body.scrollTop || document.documentElement.scrollTop;

    if (windowScrollHeight > rect.top + rect.height + rect.bottom && rect.top <= 0) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', listenToScroll);
    return () => {
      document.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const programImages = {
    essentiel: img_essentiel.publicURL,
    age: img_pplus.publicURL,
    sopk: img_splus.publicURL,
    complet: img_complet.publicURL,
  };
  const programImage = cart.contents.program
    ? programImages[cart.contents.program] || programImages.essentiel
    : programImages.essentiel;

  return (
    <div className={styles.bigcontainer}>
      <div className={styles.videoContainer}>
        <video className={`${styles.video}`} controls playsInline webkit-playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className={styles.recommandation}>RECOMMANDATION</div>

      <div className={styles.flex} id={'anchor-essential'}>
        {/* <div className={styles.options}>
          <div className={styles.optionEssential}>
            <h2>
              <div className={styles.title}>{withoutOptions} </div>
              <div className={styles.title_detail}>Pour vous apporter l'essentiel à la fertilité avec :</div>
            </h2>
            <div className={styles.containerEssentialRecom}>
              <Program isNotEditable={true} image={img_essentiel} checked />

              <div className={styles.recomText}>
                <ul style={{ margin: 'auto' }}>
                  <li className={`${styles.contents_items} p1`}>
                    <img className={styles.contents_icon} src={star.publicURL} alt="star" />
                    <div className={`${styles.contents_single_item} xs-hide sm-hide md-hide`}>
                      <span>Un&nbsp;régime&nbsp;profertilité</span> <br /> disponible&nbsp;sur&nbsp;votre&nbsp;
                      <span>
                        application <br /> BeMum®.{' '}
                      </span>
                    </div>
                    <div className={`${styles.contents_single_item} lg-hide`}>
                      <span>Un régime profertilité</span> disponible <br /> sur votre <span> application BeMum®. </span>
                    </div>
                  </li>
                  <li className={`${styles.contents_items} p1`}>
                    <img className={styles.contents_icon} src={star.publicURL} alt="star" />
                    <div className={styles.contents_single_item}>
                      2&nbsp;Téléconsultations&nbsp;avec&nbsp;votre <br /> <span>coach Fertilité.</span>
                    </div>
                  </li>
                  <li className={`${styles.contents_items} p1`}>
                    <img className={styles.contents_icon} src={star.publicURL} alt="star" />
                    <div className={styles.contents_single_item}>
                      2 compléments alimentaires <br />{' '}
                      <span className={styles.options_essentiel}>"Le duo fertilité"</span>
                    </div>
                  </li>
                </ul>
                <div className={styles.smallImgContainer}>
                  <span className={styles.smallImg}>
                    <GatsbyImage
                      image={img_recommande.childImageSharp.gatsbyImageData}
                      alt="Recommandé par des gynécologues"
                    />
                  </span>
                  <span className={styles.smallImg}>
                    <GatsbyImage image={img_fabrique.childImageSharp.gatsbyImageData} alt="Fabriqué en france" />
                  </span>
                  <span className={styles.smallImg}>
                    <GatsbyImage image={img_naturel.childImageSharp.gatsbyImageData} alt="Ingrédients naturels" />
                  </span>
                  <span className={styles.smallImg}>
                    <GatsbyImage image={img_livraison.childImageSharp.gatsbyImageData} alt="Livraison sous 48H" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {withoutOptions !== 'ESS' && (
            <div className={styles.optionOptionnal}>
              <h2 className={styles.title}>Les&nbsp;Optionnels </h2>
              <div className={styles.optionnnalContent}>
                {score.antiox >= 20 && (
                  <Product
                    checked={cart.contents.items.find((item) => item.id === products.ANTIOX)}
                    item={cart.definition.availableProducts['antiox']}
                    image={img_pplus}
                    title="Spécificité + 35 ans"
                  />
                )}
                {score.poids >= 20 && (
                  <Product
                    checked={cart.contents.items.find((item) => item.id === products.EQUILIBRE)}
                    item={cart.definition.availableProducts['equilibre']}
                    image={img_splus}
                    title="Spécificité poids / SOPK"
                  />
                )}
                {score.coaching >= 20 && (
                  <Product
                    checked={cart.contents.items.find((item) => item.id === products.COACHING)}
                    item={cart.definition.availableProducts['coaching']}
                    image={img_coaching}
                    title="COACHING+"
                  />
                )}
              </div>
            </div>
          )}
        </div> */}

        <div ref={ref} className={isFixed ? styles.recap__hidden__mobile : ''}>
          <Recap
            optionName={optionName}
            sentenceResult={sentenceResult}
            firstname={firstname}
            cart={cart}
            image={programImage}
          />
        </div>

        <div className={isFixed ? styles.recap__fixed : `${styles.recap__hidden__mobile} ${styles.mobile}`}>
          <RecapFixed
            optionName={optionName}
            sentenceResult={sentenceResult}
            firstname={firstname}
            cart={cart}
            arrow={arrow_scroll_down}
            isFixed
          />
        </div>
      </div>
    </div>
  );
}

function Recap({ optionName, sentenceResult, firstname, cart, image }) {
  return (
    <div className={styles.recap_container}>
      <div className={styles.recap_content}>
        <div>
          <img className={styles.recap_image} src={image} alt={optionName?.name} />
        </div>

        <div className={styles.recap_text}>
          {optionName && (
            <div className={styles.recap_title}>
              Votre&nbsp;recommandation : <span className={styles.recap_name}> Programme {cart.contents.program}</span>
              &nbsp;
              {/* <sup>{optionName.plus}</sup> */}
            </div>
          )}

          <p>
            {firstname}, <span className="Medium">{sentenceResult}</span>, répondront parfaitement à votre besoin selon
            nos scientifiques et nos coachs <span className="Medium">spécialisés en fertilité féminine</span>. <br />
            Le diagnostic BeMum est <span className="Medium">approuvé par les gynécologues</span>.
          </p>

          <div className={styles.subtotal}>
            <b>{cart.subtotal}€</b> / MOIS, SANS ENGAGEMENT
          </div>

          <CallToAction />
        </div>
      </div>
    </div>
  );
}

function RecapFixed({ optionName, sentenceResult, firstname, cart, arrow }) {
  const [toogleMore, setToogleMore] = useState(false);
  const toogleMoreClickHandler = useCallback(() => setToogleMore(!toogleMore), [toogleMore, setToogleMore]);

  return (
    <div className={`${styles.recap} ${styles.recap_wrapper__fixed}`}>
      <button
        className={`${styles.recap_btn} ${toogleMore ? '' : styles.recap_btn_rotated}`}
        onClick={toogleMoreClickHandler}
      >
        <img src={arrow.publicURL} alt="arrow" />
      </button>

      {optionName && (
        <div className={styles.recap_title}>
          Votre&nbsp;recommandation <span className={styles.recap_name}>{optionName.name}</span>&nbsp;
          <sup>{optionName.plus}</sup>
        </div>
      )}

      {toogleMore && (
        <p className={styles.recap_content}>
          {firstname}, <span className="Medium">{sentenceResult}</span>, répondront parfaitement à votre besoin selon
          nos scientifiques et nos coachs <span className="Medium">spécialisés en fertilité féminine</span>. <br />
          Le diagnostique BeMum est <span className="Medium">approuvé par les gynécologues</span>.
        </p>
      )}

      <div className={styles.subtotal}>
        <b>{cart.subtotal}€</b> / MOIS, SANS ENGAGEMENT
      </div>
      <CallToAction />
    </div>
  );
}

export default Essential;
