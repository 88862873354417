// extracted by mini-css-extract-plugin
export var calendly = "resultats-module--calendly--nYEAk";
export var calendly_subtitle = "resultats-module--calendly_subtitle--HiSlT";
export var calendly_title = "resultats-module--calendly_title--TRhrR";
export var comment = "resultats-module--comment--kHDYm";
export var container = "resultats-module--container--Ccfy7";
export var contents_items = "resultats-module--contents_items--RkTcq";
export var contents_single_item_title = "resultats-module--contents_single_item_title--sXyIz";
export var goals_image__desktop = "resultats-module--goals_image__desktop--UvhGm";
export var goals_image__mobile = "resultats-module--goals_image__mobile--R9lin";
export var green_text = "resultats-module--green_text--StoqK";
export var line = "resultats-module--line--OzIYp";
export var profile = "resultats-module--profile--1Ktv6";
export var profile_container = "resultats-module--profile_container--nC2cY";
export var profile_content = "resultats-module--profile_content--Nn8ut";
export var profile_content__title = "resultats-module--profile_content__title--OWai0";
export var profile_description = "resultats-module--profile_description--4c-T-";
export var profile_optionName = "resultats-module--profile_optionName--X0qHj";
export var profile_optionName_plus = "resultats-module--profile_optionName_plus--0GI5s";
export var progress_text = "resultats-module--progress_text--yglh3";
export var progress_text_visible = "resultats-module--progress_text_visible--j3xuo";
export var progress_wrapper = "resultats-module--progress_wrapper--rKw6x";
export var red_text = "resultats-module--red_text--ZZLtn";
export var result_child_description = "resultats-module--result_child_description--t4zDC";
export var result_description = "resultats-module--result_description--vCNEv";
export var result_description_container = "resultats-module--result_description_container--L9u09";
export var result_description_left_side = "resultats-module--result_description_left_side--siQbD";
export var result_description_right_side = "resultats-module--result_description_right_side--DX2Az";
export var result_description_title = "resultats-module--result_description_title--7uUaK";
export var tags_wrapper = "resultats-module--tags_wrapper--YSwIJ";
export var video = "resultats-module--video--rZnYL";