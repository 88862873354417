// extracted by mini-css-extract-plugin
export var bigcontainer = "essential-module--bigcontainer--7eS9M";
export var black = "essential-module--black--4NE-m";
export var button = "essential-module--button--LQKqQ";
export var containerEssentialRecom = "essential-module--containerEssentialRecom--xVmEq";
export var contents_icon = "essential-module--contents_icon--fk+rM";
export var contents_items = "essential-module--contents_items--oCRFD";
export var contents_single_item = "essential-module--contents_single_item--us-XT";
export var flex = "essential-module--flex--95-iQ";
export var img = "essential-module--img--Dpj0n";
export var mobile = "essential-module--mobile--8ppJT";
export var optionEssential = "essential-module--optionEssential--87ImG";
export var optionOptionnal = "essential-module--optionOptionnal--q+LDB";
export var optionnnalContent = "essential-module--optionnnalContent--AKTs9";
export var options = "essential-module--options--xojN+";
export var options_essentiel = "essential-module--options_essentiel--Ja0Hh";
export var price = "essential-module--price--LL4fH";
export var price_button = "essential-module--price_button--yvgBD";
export var price_contents = "essential-module--price_contents--Bh7sE";
export var product_why = "essential-module--product_why--51YMY";
export var recap = "essential-module--recap--3Tcd1";
export var recap__fixed = "essential-module--recap__fixed--WUFZy";
export var recap__hidden__mobile = "essential-module--recap__hidden__mobile--9F+PE";
export var recap_btn = "essential-module--recap_btn--eAHuq";
export var recap_btn_rotated = "essential-module--recap_btn_rotated--Zzk2H";
export var recap_container = "essential-module--recap_container--8fmjN";
export var recap_content = "essential-module--recap_content--+Ktu5";
export var recap_image = "essential-module--recap_image--AssX-";
export var recap_name = "essential-module--recap_name--PoU3d";
export var recap_text = "essential-module--recap_text--8IEn3";
export var recap_title = "essential-module--recap_title--SgIP8";
export var recap_wrapper__fixed = "essential-module--recap_wrapper__fixed--OtTzu";
export var recomText = "essential-module--recomText--xVNRU";
export var recommandation = "essential-module--recommandation--uJAxb";
export var smallImg = "essential-module--smallImg--bbMIN";
export var smallImgContainer = "essential-module--smallImgContainer--nIyD-";
export var star_icon = "essential-module--star_icon--5Wyde";
export var subtotal = "essential-module--subtotal--oWsoP";
export var title = "essential-module--title--eYPYk";
export var title_detail = "essential-module--title_detail--SezF2";
export var video = "essential-module--video--Nke4Y";
export var videoContainer = "essential-module--videoContainer--VlP7P";