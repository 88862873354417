import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import CartContext, { programs } from '../../context/CartContext';
import * as styles from './rec-program.module.scss';

function Program({ image, title, checked, item, isNotEditable, scoreObject }) {
  const { cart, setCart } = useContext(CartContext);

  // Default price if item is not provided
  const price = item ? item.price : '61,50';
  const membershipPeriod = cart.membershipPeriod || 'monthly';

  // Check if item and item.id are defined before using them
  const cartItem = item && cart.contents.items.find((cartItem) => cartItem.id === item.id);
  const displayPrice = cartItem ? cartItem.price : item?.price?.[membershipPeriod] || '61,50';

  // 🛠 Récupérer le programme depuis cart.content.program
  const recommendedProgram = cart.contents.program || 'essentiel'; // Par défaut "essentiel"
  const programName = programs[recommendedProgram]?.name || recommendedProgram; // Nom du programme

  // Vérification du score pour ajuster le programme recommandé
  if (scoreObject) {
    const { antiox, poids } = scoreObject;

    if (antiox >= 20 && poids >= 20) {
      setCart('add', 'program', 'complet');
    } else if (antiox >= 20) {
      setCart('add', 'program', 'age');
    } else if (poids >= 20) {
      setCart('add', 'program', 'sopk');
    } else {
      setCart('add', 'program', 'essentiel');
    }
  }

  function toggleChecked() {
    if (isNotEditable) return;
    if (checked) return item && setCart('remove', 'product', item.id);
    return item && setCart('add', 'product', item.id);
  }

  return (
    <div className={styles.productContainer}>
      {title && <h3 style={{ fontSize: '17px' }}>{title}</h3>}

      <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />

      {/* Affichage du programme recommandé */}
      <div className={styles.recommendations}>
        <strong>Programme recommandé :</strong>
        <span>{programName}</span>
      </div>

      <button
        onClick={toggleChecked}
        className={`Btn Btn--Reg Btn--Circle-Icon ${checked ? `${styles.black} Btn--Circle-Icon--Checked ` : ''} ${
          styles.button
        }`}
      >
        {displayPrice}€&nbsp;<span className="xs-hide sm-hide md-hide">{checked ? 'Ajouté' : 'Ajouter'}</span>
      </button>
    </div>
  );
}

export default Program;
